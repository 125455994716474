// if you need to add custom js put it in here
var nonLinearSlider = document.getElementById('nonlinear');
//if (nonLinearSlider != null) {
//noUiSlider.create(nonLinearSlider, {
//    connect: true,
//    behaviour: 'tap',
//    start: [0, 5000000],
//    pips: {
//        mode: 'positions',
//        values: [0, 50, 100],
//        density: 4,
//        stepped: true,
//        format: wNumb({
//            decimals: 0,
//            thousand: ',',
//            prefix: '£',
//        })
//    },
//    tooltips: [true, wNumb({ decimals: 0, thousand: ',', prefix: '£' })],
//    step: 10000,
//    range: {
//        // Starting at 50,000, step the value by 10,000,
//        // until 250,000 is reached. From there, step by 25,000.
//        'min': [0],
//        '50%': [250000, 25000],
//        '60%': [500000, 50000],
//        '70%': [700000, 100000],
//        '80%': [1000000, 250000],
//        '85%': [2000000, 500000],
//        '90%': [3000000, 1000000],
//        '95%': [5000000, 2500000],
//        'max': [5000000]
//    },
//    format: wNumb({
//        decimals: 0,
//        thousand: ',',
//        prefix: '£',
//    })
//});
//}
// Write the CSS 'left' value to a span.
function leftValue(handle) {
    return handle.parentElement.style.left;
}
var lowerValue = document.getElementById('lower-value'),
    lowerOffset = document.getElementById('lower-offset'),
    upperValue = document.getElementById('upper-value'),
    upperOffset = document.getElementById('upper-offset'),
    handles = nonLinearSlider.getElementsByClassName('noUi-handle');
// Display the slider value and how far the handle moved
// from the left edge of the slider.
/*LinearSlider.noUiSlider.on('update', function ( values, handle ) {
    if ( !handle ) {
        lowerValue.innerHTML = 'Min Price: ' + values[handle] + ' |' ;
    } else {
        upperValue.innerHTML = 'Max Price: ' + values[handle];
    }*/
//});
var nonLinearSlider = document.getElementById('nonlinear-2');
if (nonLinearSlider != null){
noUiSlider.create(nonLinearSlider, {
    connect: true,
    behaviour: 'tap',
    start: [0, 100000],
    pips: {
        mode: 'positions',
        values: [0, 50, 100],
        density: 4,
        stepped: true,
        format: wNumb({
            decimals: 0,
            thousand: ',',
            prefix: '£',
            postfix: ' PA',
        })
    },
    tooltips: [true, wNumb({ decimals: 0, thousand: ',', prefix: '£', postfix: ' PA', })],
    step: 100,
    range: {
        // Starting at 100, step the value by 100,
        // until 1,000 is reached. From there, step by 250.
        'min': [0],
        '65%': [10000, 250],
        '85%': [30000, 2500],
        '95%': [50000, 5000],
        'max': [100000]
    },
    format: wNumb({
        decimals: 0,
        thousand: ',',
        prefix: '£',
        postfix: ' PA',
    })
});
}
// Write the CSS 'left' value to a span.
function leftValue(handle) {
    return handle.parentElement.style.left;
}
var lowerValue = document.getElementById('lower-value'),
    lowerOffset = document.getElementById('lower-offset'),
    upperValue = document.getElementById('upper-value'),
    upperOffset = document.getElementById('upper-offset'),
    handles = nonLinearSlider.getElementsByClassName('noUi-handle');
// Display the slider value and how far the handle moved
// from the left edge of the slider.
/*LinearSlider.noUiSlider.on('update', function ( values, handle ) {
    if ( !handle ) {
        lowerValue.innerHTML = 'Min Price: ' + values[handle] + ' |' ;
    } else {
        upperValue.innerHTML = 'Max Price: ' + values[handle];
    }*/
//});

// for quicksearch widget
$(document).on('click', '.quick-search-btn', function () {
    var selectObj = $('#Property-Type');
    var selectedVal = selectObj.val();
    var attrId = selectObj.data("attr-id");
    var url = $(this).data("url-to-call");
    var forSaleRadio = $('#for-sale-radio');
    var forLetRadio = $('#for-let-radio');

    // setup url call
    var attrList = [];
    // add property type vals
    attrList.push(attrId + "|" + selectedVal);
    // add radio vals

    // get radio value
    var radioAttrId = $('input[name=quick-search-radio-field]:checked').data('attr-id');
    var radioAttrValId = $('input[name=quick-search-radio-field]:checked').data('attr-val-id');
    attrList.push(radioAttrId + "|" + radioAttrValId);

    var newQuerystring = "";
    var cnt = 0;
    for (var i in attrList) {
        newQuerystring += attrList[i];
        cnt++;
        if (cnt < attrList.length) {
            newQuerystring += ",";
        }
    }

    var newUrl = UpdateQueryString("pf", newQuerystring, url);

    // price ranges
    if (forSaleRadio.is(':checked')) {
        var slider = document.getElementById('nonlinear');
        var vals = slider.noUiSlider.get();
        var minPrice = vals[0].replace('£', '').replace(' PA', '');
        var maxPrice = vals[1].replace('£', '').replace(' PA', '');
        console.log("min: " + minPrice + " max: " + maxPrice)

        if (typeof minPrice != 'undefined' && minPrice != -1) {
            newUrl = UpdateQueryString("minprice", minPrice, newUrl);
        }
        if (typeof maxPrice != 'undefined' && maxPrice != -1) {
            newUrl = UpdateQueryString("maxprice", maxPrice, newUrl);
        }
    }
    if (forLetRadio.is(':checked')) {
        var slider = document.getElementById('nonlinear-2');
        var vals = slider.noUiSlider.get();
        var minPrice = vals[0].replace('£', '').replace(' PA', '');
        var maxPrice = vals[1].replace('£', '').replace(' PA', '');
        console.log("min: " + minPrice + " max: " + maxPrice)

        if (typeof minPrice != 'undefined' && minPrice != -1) {
            newUrl = UpdateQueryString("minprice", minPrice, newUrl);
        }
        if (typeof maxPrice != 'undefined' && maxPrice != -1) {
            newUrl = UpdateQueryString("maxprice", maxPrice, newUrl);
        }
    }

    console.log(newUrl);
    // call url
    window.location = newUrl;

});
$(document).ready(function ()
{
    $(".quick-search-show-for-sale").trigger("click");
});
